import React from 'react';
import { Link } from '@remix-run/react';

import Button, { ButtonProps } from '~/components/Button';
import { IconName } from '~/components/Icon';
import { useSound } from '~/hooks/useSound';
import { cn } from '~/utils/cn';

type MainLinkProps = {
  to?: string;
  label: string;
  icon: IconName;
  variant?: ButtonProps['variant'];
  soon?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  as?: React.ElementType;
};

const MainLink = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  MainLinkProps
>(({ to, label, icon, variant, soon, onClick, as, ...props }, ref) => {
  const playSound = useSound('/sounds/click_003.mp3');

  const handleClick = (e: React.MouseEvent) => {
    playSound();
    if (onClick) {
      onClick(e);
    }
  };

  // Determine the component to use
  const Component = as || (onClick && !to ? 'button' : Link);

  const linkProps = Component === Link ? { to } : {};
  const buttonProps = Component === 'button' ? { type: 'button' } : {};

  const content = (
    <>
      <Button
        as="div"
        size="small"
        className={cn(
          'size-8 md:size-9 rotate-[4deg] *:mb-0.5 [.main-link:nth-child(odd)_&]:rotate-[-4deg] rounded-lg p-0 before:rounded-lg after:rounded-lg'
        )}
        icon={icon}
        variant={variant}
        aria-hidden
      />
      <span className="transition-transform group-hover/text:translate-x-1">
        <span className="h-xl md:h-3xl">{label}</span>
      </span>
      {soon && (
        <span className="h-base relative self-start !font-nanum text-[20px] font-normal lowercase md:text-[30px]">
          Soon...
        </span>
      )}
    </>
  );

  return (
    <Component
      {...linkProps}
      {...buttonProps}
      ref={ref}
      href={Component !== Link && to ? to : undefined}
      className={cn(
        'flex gap-3 main-link max-md:items-center group/text',
        Component === 'button' ? 'text-left' : ''
      )}
      data-cy={`${label}-link`}
      onClick={handleClick}
      {...props}
    >
      {content}
    </Component>
  );
});

MainLink.displayName = 'MainLink';

export default MainLink;
