import React, { useState } from 'react';
import { Link, useLoaderData } from '@remix-run/react';
import { motion } from 'motion/react';

import transparentPattern from '~/assets/images/pattern-transparent.png';
import DailyRiddleTimer from '~/components/DailyRiddleTimer';
import FooterLinks from '~/components/FooterLinks';
import MainMenuLink from '~/components/MainMenuLink';
import Modal from '~/components/Modal';
import SurvivalModeModal from '~/components/SurvivalModeModal';
import { useEnv } from '~/hooks/useEnv';
import { ENV } from '~/types';
import { envLoader } from '~/utils/env';

const backgroundImages = Array.from(
  { length: 50 },
  (_, i) => `/homepage/${i + 1}.webp`
);

export const loader = envLoader;

export default function Index() {
  const env = useLoaderData<ENV>();
  useEnv(env);
  const [isSurvivalModalOpen, setIsSurvivalModalOpen] = useState(false);
  const [backgroundImage] = useState(
    () => backgroundImages[Math.floor(Math.random() * backgroundImages.length)]
  );

  const handleSurvivalClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsSurvivalModalOpen(true);
  };

  return (
    <div className="min-h-viewport relative flex flex-col overflow-hidden px-4 pb-safe-offset-6 pt-safe-offset-36 md:px-10 md:pb-safe-offset-10">
      {/* Background */}
      <div className="absolute inset-0 z-0 size-full">
        <div
          className="absolute inset-0 z-10 size-full"
          style={{
            backgroundImage: `url(${transparentPattern})`
          }}
        />
        <div className="left-gradient absolute inset-0 z-10 size-full" />
        <motion.img
          initial={{ scale: 1.1 }}
          animate={{ scale: 1 }}
          transition={{
            duration: 3,
            ease: 'circOut'
          }}
          src={backgroundImage}
          alt="background"
          className="size-full object-cover opacity-50"
          loading="eager"
        />
      </div>

      {/* Main links */}
      <div className="relative mb-12 flex w-fit flex-col gap-3 uppercase">
        <MainMenuLink
          to="/daily"
          label="Guess of the day"
          icon="question"
          variant="green"
        />
        <MainMenuLink
          to="/past-30-days"
          label="Past 30 days"
          icon="calendar"
          variant="blue"
        />
        <MainMenuLink
          label="Survival mode"
          icon="skull"
          variant="red"
          onClick={handleSurvivalClick}
        />
      </div>

      <div className="relative mt-auto space-y-6 max-md:mb-6">
        <FooterLinks />

        {/* Legal links */}
        <div className="mt-6 flex w-fit flex-wrap gap-x-4 gap-y-2 *:whitespace-nowrap">
          <Link to="/privacy-policy" className="p-sm opacity-70">
            Privacy policy
          </Link>
          <Link to="/terms-and-conditions" className="p-sm opacity-70">
            Terms & conditions
          </Link>
          <Link to="/copyright-complaints" className="p-sm opacity-70">
            Copyright complaints
          </Link>
        </div>
      </div>

      {/* Time left */}
      <DailyRiddleTimer />

      {/* Survival Mode Modal */}
      <Modal
        isOpen={isSurvivalModalOpen}
        onClose={() => setIsSurvivalModalOpen(false)}
        size="small"
        hideCloseButton
      >
        <SurvivalModeModal onClose={() => setIsSurvivalModalOpen(false)} />
      </Modal>
    </div>
  );
}
