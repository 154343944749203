import React, { useState } from 'react';
import { Link } from '@remix-run/react';

import Button from '~/components/Button';
import Icon from '~/components/Icon';
import Login from '~/components/Login';
import Modal from '~/components/Modal';
import { useAuth } from '~/contexts/AuthContext';

type SurvivalModeModalProps = {
  onClose: () => void;
};

const SurvivalModeModal = ({ onClose }: SurvivalModeModalProps) => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const { isAuthenticated } = useAuth();

  return (
    <div
      className="p-base relative flex flex-col gap-6"
      data-cy="survival-mode-modal"
    >
      <h1 className="h-3xl font-bold uppercase tracking-wider">
        SURVIVAL MODE
      </h1>

      <p>Put your gaming knowledge to the ultimate test !</p>

      <div className="flex flex-col gap-2">
        <p>You begin with 60 seconds.</p>
        <div className="flex items-center gap-3">
          <Icon name="star" size={6} />
          <p>
            <span>Every correct answer give you </span>
            <span className="font-bold text-green-500">+15 sec</span>
          </p>
        </div>

        <div className="flex items-center gap-3">
          <Icon name="star" size={6} />
          <p>
            <span>Every wrong answer cost you </span>
            <span className="whitespace-nowrap font-bold text-red-500">
              -8 sec
            </span>
          </p>
        </div>

        <div className="flex items-center gap-3">
          <Icon name="star" size={6} />
          <p>
            <span>You can skip a game </span>
            <span className="whitespace-nowrap font-bold text-red-500">
              -12 sec
            </span>
          </p>
        </div>
      </div>

      <p>How long can you survive?</p>

      <div className="flex w-full items-end justify-between gap-4">
        <Button
          variant="purple"
          size="small"
          className="h-fit"
          onClick={onClose}
        >
          Back
        </Button>

        {isAuthenticated && (
          <Button as={Link} to="/survival" variant="green">
            Let's Go
          </Button>
        )}

        {!isAuthenticated && (
          <>
            <Button variant="green" onClick={() => setIsLoginModalOpen(true)}>
              Sign in to play
            </Button>
            <Modal
              isOpen={isLoginModalOpen}
              onClose={() => setIsLoginModalOpen(false)}
              size="small"
              disabled={isAuthenticated}
            >
              <Login />
            </Modal>
          </>
        )}
      </div>
    </div>
  );
};

export default SurvivalModeModal;
